<template>
  <v-container fill-height fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col align="center">
        <v-progress-circular
          indeterminate
          color="#FF6465"
        ></v-progress-circular>
        <v-spacer></v-spacer>
        Please wait..
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: "Callback",
  },
  name: "CallbackPage",
  methods: {
    async handleCallback() {
      try {
        await this.$store.dispatch('authModule/getUser');

        const user = this.$store.getters['authModule/getUser'];

        if (user) {
          const role = user.role;
          switch (role) {
            case 'teacher':
              this.$router.push("/workplace");
              break;
            case 'student':
              this.$router.push("/classroom");
              break;
            default:
              this.$router.push("/main");
              break;
          }
        } else {
          this.$router.push("/main");
        }
      } catch (error) {
        this.$auth.logout({ logoutParams: { returnTo: window.location.origin } });
      }
    }
  },
  async mounted() {
    await this.handleCallback();
  }

};
</script>

<style scoped></style>
